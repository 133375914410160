import React from "react"
import { Layout, SEO, Cloud, HomeAbout, HomeProjects, HomeBlog, Mission } from "../components";

const IndexPage = () => (
  <Layout>
    <SEO title="Natural Silver Solutions | Professional Bio-Active colloidal silver supplement" description="Natural Silver Solutions. Professional Bio-Active colloidal silver supplement, delivers advantages no other manufacturer can match"/>
    <Cloud/>
    <HomeAbout/>
    <Mission/>
    <HomeProjects/>
    <HomeBlog/>    
  </Layout>
)

export default IndexPage
